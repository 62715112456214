import React from 'react';
import useStyles from 'isomorphic-style-loader-react18/useStyles';
import WalletSelect from '../../emoney/Wallet/Select';
import useLogin from '../../iam/Login/hooks';
import hamburger from './hamburger.svg';
import s from './Header.less';

interface HeaderProps {
  setShowSidebar: (show: boolean) => void;
}
const Header = ({ setShowSidebar }: HeaderProps) => {
  useStyles(s);
  const { isVerified } = useLogin();

  return (
    <div className={s.root}>
      <div className="container">
        <div className="row g-0 align-items-center">
          <div className="col-auto d-lg-none">
            <button
              type="button"
              className={s.hamburgerBtn}
              onClick={() => {
                if (typeof setShowSidebar === 'function') setShowSidebar(true);
              }}
            >
              <img src={hamburger} className={s.hamburgerIcon} alt="Menu" />
            </button>
          </div>
          <div className="col">
            <div className={s.profileContainer}>
              {isVerified && <WalletSelect />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
