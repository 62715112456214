import React from 'react';

const Connect = () => {
  return (
    <a href="/link/noble">
      <div
        style={{
          background: '#0F1331',
          padding: '15px',
          margin: '20px',
          fontSize: '1.1rem',
          color: '#fff',
          borderRadius: '13px',
        }}
      >
        We are excited to support Noble
        <br /> <br />
        <img
          src="/assets/emoney/wallets/keplr-icon.svg"
          alt="Keplr wallet"
          style={{
            width: '14%',
            borderRadius: '8px',
            marginRight: '10px',
          }}
        />
        <span style={{ fontSize: '.8rem' }}>Connect Keplr wallet here</span>
      </div>
    </a>
  );
};
export default Connect;
